/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';

/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js'; // Included with Angular CLI

/***************************************************************************************************
 * Polyfills for Evergreen Browsers (Required for modern browsers)
 */
import 'core-js/features/symbol';           // Polyfill for Symbol
import 'core-js/features/object';           // Polyfill for Object
import 'core-js/features/function';         // Polyfill for Function
import 'core-js/features/parse-int';        // Polyfill for parseInt
import 'core-js/features/parse-float';      // Polyfill for parseFloat
import 'core-js/features/number';           // Polyfill for Number
import 'core-js/features/math';             // Polyfill for Math
import 'core-js/features/string';           // Polyfill for String
import 'core-js/features/date';             // Polyfill for Date
import 'core-js/features/array';            // Polyfill for Array
import 'core-js/features/regexp';           // Polyfill for RegExp
import 'core-js/features/map';              // Polyfill for Map
import 'core-js/features/set';              // Polyfill for Set

/***************************************************************************************************
 * Polyfills for Legacy Browsers (Optional)
 */
import 'classlist.js'; // For older browsers that do not support classList API
import 'web-animations-js'; // For browsers that do not support Web Animations API

/***************************************************************************************************
 * Internationalization (Optional)
 */
import 'intl'; // Required for i18n pipes in older browsers
import 'intl/locale-data/jsonp/en-US'; // Locale data for en-US
import 'core-js/stable'; // This imports all stable polyfills
import 'regenerator-runtime/runtime'; // For async/await support